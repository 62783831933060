import { WebDevelopmentIcon } from "../../components/layout/icons/WebDevelopmentIcon";
import React from "react";
import { ButtonColour, ButtonConfig, ButtonSize } from "../../components/layout/Button";

export const webDevelopmentData: WebDevPageData = {
    metaPageTitle: "Web Development & TwinSpires Case Study - iDream Interactive",
    metaKeywords: "web development services, web application development, web app development, custom web development, web development jobs",
    metaDescription: "We specialize in developing technology to make the fastest, most optimized websites. Chat with us about updating the technology behind your website!",
    title: "Web Development",
    subtitle: "We specialize in developing technology to make the fastest, most optimized websites.",
    icon: <WebDevelopmentIcon fill={"#ffffff"} />,
    description:
    {
        title: "We believe in customizing the latest and greatest technology to fit each client's individual needs.",
        description: <>
            <p>Perhaps you are experiencing slowness or compatibility issues.  Maybe you need a custom widget created or thousands of blog posts migrated, we can do that too.  Have you seen something on another website and want something similar?  Leave it up to us to make it happen.</p>
            <p>If your solution requires serious web development, that’s where we shine.  Let’s connect to better understand your requirements.</p>
        </>,
    },
    caseStudy: {
        deliverables: ["Web Development", "SaaS Development", "UI/UX Design", "Front-end Development", "Back-end Development"],
        client: "TwinSpires",
        title: "The TwinSpires Edge",
        description: <>
            <p>
                We were approached by the Twin Spires team at Churchill Downs to improve their blog site - TwinSpires Edge.
                They originally had a WordPress CMS (Content Management System) with many problematic third-party plugins.
                Their blog site was slow and required far too much DevOps attention.
            </p>
            <p>
                We recommended changing their entire frontend and backend architecture, along with creating a brand new headless CMS.
            </p>
            <p>
                By using React and Gatsby as their frontend solution, they loaded their static site onto the Netlify CDN (Content Delivery Network).
                This change decreased their load times by 400%.
            </p>
            <p>
                The TwinSpires team has full control over updating their content through the headless CMS without the need to update any plugins or servers.
            </p>
        </>,
        buttonConfig: {
            colour: ButtonColour.BLUE,
            ctaLabel: "Live Site",
            ctaLink: "https://edge.twinspires.com",
            externalLink: true,
            size: ButtonSize.LARGE,
            id: "",
            ariaLabel: "The Twin Spires Edge live site"
        }
    }
}

type WebDevPageData = {
    metaPageTitle: string,
    metaKeywords: string,
    metaDescription: string,
    title: string,
    subtitle: string,
    icon: JSX.Element,
    description: SoftwareServiceDescription,
    caseStudy: CaseStudyData,
}

export type SoftwareServiceDescription = {
    title: string,
    description: JSX.Element
}

export type CaseStudyData = {
    title: string,
    description: JSX.Element,
    buttonConfig?: ButtonConfig,
    client: string,
    deliverables: string[],
}