import Img, { FluidObject } from "gatsby-image";
import React, { useState } from "react";
import { createRef } from "react";
import { useRef } from "react";
import { ArrowIcon } from "./icons/ArrowIcon";

interface Props {
    isNative: boolean,
    images: FluidObject[],
}


export const Gallery: React.FC<Props> = ({ isNative, images }) => {
    const [index, setIndex] = useState<number>(0);

    const scroll = useRef<HTMLDivElement>(null);
    const imageDivs = useRef([]);

    if (imageDivs.current.length !== images.length) {
        // add or remove refs
        imageDivs.current = Array(images.length).fill({}).map((_, i) => imageDivs.current[i] || createRef<HTMLDivElement>());
    }

    const previous = () => {
        scrollIt(index, -1);
    }

    const next = () => {
        scrollIt(index, 1);
    }

    const scrollIt = (i: number, direction: number) => {
        if ((i + direction) >= images.length) {
            i = images.length - 1;
        }

        if ((i + direction) < 0) {
            i = 0;
        }

        if (!(scroll.current && imageDivs.current[i])) {
            return;
        }

        let maxScroll = scroll.current.scrollWidth - scroll.current.clientWidth;
        let attemptedScroll = scroll.current.scrollLeft;
        let left;

        if (direction === 1 && attemptedScroll === maxScroll) {
            left = 0;
            i = 0;
            setIndex(i);
            scroll.current?.scrollTo({ left, behavior: "smooth" });
            return;
        } else if (direction === -1 && attemptedScroll === 0) {
            left = maxScroll;
            i = 0;
            setIndex(i);
            scroll.current?.scrollTo({ left, behavior: "smooth" });
            return;
        }

        attemptedScroll += (imageDivs.current[i] as any).current.clientWidth * direction;

        left = attemptedScroll;
        if (left >= maxScroll) {
            left = maxScroll;
        }

        scroll.current?.scrollTo({ left, behavior: "smooth" });
        i += direction;
        setIndex(i);
    }

    const renderImages = () => {
        const elements: JSX.Element[] = [];

        for (let i = 0; i < images.length; ++i) {
            let className = `image-container ${i === 1 ? "skinny" : "wide"}`;

            if (isNative) {
                className = "image-container skinny";
            }

            const element = (
                <div
                    key={`gallery-image-${i}`}
                    className={className}
                    ref={imageDivs.current[i]}
                >
                    <Img fluid={images[i]} alt={`Case Study Gallery: Image ${i + 1}`} />
                </div>
            );
            elements.push(element);
        }

        return elements;
    }

    return (
        <div className="gallery">
            <button aria-label="Scroll Left" onClick={previous} className="previous-button"><ArrowIcon fill="#ffffff" /></button>
            <button aria-label="Scroll Right" onClick={next} className="next-button"><ArrowIcon fill="#ffffff" /></button>
            <div className={`gallery-scroll`} ref={scroll}>
                <div className="images">
                    {renderImages()}
                </div>
            </div>
        </div>
    )
}