import React from "react";
import { Button } from "../../layout/Button";
import { CaseStudyData } from "../../../content/pageData/WebDevelopmentData";
import { BlobThree } from "../../layout/blobs/BlobThree";
import { BlobOne } from "../../layout/blobs/BlobOne";

interface Props {
    className?: string,
    caseStudy: CaseStudyData,
}


export const CaseStudy: React.FC<Props> = ({ caseStudy, className = "" }) => {
    const renderDeliverables = () => {
        const elements: JSX.Element[] = [];

        for (let i = 0; i < caseStudy.deliverables.length; ++i) {
            const element = <p key={`case-study-deliverable-${i}`}>{caseStudy.deliverables[i]}</p>;
            elements.push(element);
        }
        return elements;
    }

    return (
        <div className={`case-study ${className}`}>
            <div className="blob-container">
                <BlobOne fill={"#080a2e08"} />
                <BlobThree fill={"#080a2e08"} />
            </div>
            <h3 className="heading">Case Study</h3>
            <div className="case-study-content">
                <div className="description">
                    <h2 className="case-study-product-title">{caseStudy.title}</h2>
                    {caseStudy.description}
                    {caseStudy.buttonConfig &&
                        <Button
                            buttonConfig={{
                                ...caseStudy.buttonConfig,
                                className: "large-show",
                                id: "largeGradient",
                            }}
                        />
                    }
                </div>
                <div className="info">
                    <div className="info-list">
                        <h2>Client</h2>
                        <p>{caseStudy.client}</p>
                    </div>
                    <div className={`info-list ${caseStudy.buttonConfig ? "" : "last"}`}>
                        <h2>Deliverables</h2>
                        {renderDeliverables()}
                    </div>
                </div>
                {caseStudy.buttonConfig &&
                    <Button
                        buttonConfig={{
                            ...caseStudy.buttonConfig,
                            className: "small-show",
                            id: "smallGradient",
                        }}
                    />
                }
            </div>
        </div>
    )
}